<!--
 * @Author: gaojingran
 * @Date: 2021-04-14 15:24:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-08 14:53:12
 * @Description: 下载终稿抽屉
-->
<style lang="less" scoped>
.end-draft-item {
  display: flex;
  margin-bottom: 15px;
  .lable {
    flex: 0 0 100px;
    width: 100px;
    margin-right: 10px;
    text-align: right;
    color: @text_color_1;
    font-size: @font_size_2;
  }
  .text {
    flex: 1;
    width: 0;
    color: @text_color_2;
    font-size: @font_size_2;
    .download-wrap {
      /deep/.ant-space {
        flex-wrap: wrap;
        .ant-btn-link {
          display: flex;
          height: 17px;
          margin-bottom: 4px;
          align-items: center;
        }
      }
    }
  }
  // .textb {
  //   font-weight: 400;
  //   line-height: 16px;
  //   color: #4080ff;
  // }
}
</style>

<template>
  <a-drawer :width="460" :visible="visible" @close="handleClose">
    <template v-slot:title>
      <a-space :size="10">
        <span class="bold">{{ $t('orderInfo.btn_download_finish') }}</span>
        <NeoTag shape type="warning">
          <span class="status" v-if="info.isTerminate === 1">{{ localeDict.orderStatus[info.status] | defined }}</span>
          <span class="status" v-else>{{ localeDict.orderStatus[Math.min(9, info.status)] | defined }}</span>
        </NeoTag>
      </a-space>
    </template>
    <NeoDrawerLayout :showFooter="true">
      <!-- 待确认终稿 & 有autoConfirmDate - 当前时间倒推 -->
      <div class="error mb-4" v-if="showAutoConfirmDate">
        <a-icon type="exclamation-circle" theme="filled" />
        <span class="pl-1">{{ $t('orderInfo.autoConfirmDate', autoConfirmDate) }}</span>
      </div>
      <div class="end-draft-item">
        <span class="lable">{{ $t('orderInfo.order_name') }}:</span>
        <span class="text">{{ info.name }}</span>
      </div>
      <div class="end-draft-item">
        <span class="lable">{{ $t('orderInfo.doc_type') }}:</span>
        <!-- <span class="text" :title="$store.getters['app/getDictLabel']('DOC_TYPE', info.documentType) | defined">
          {{ $store.getters['app/getDictLabel']('DOC_TYPE', info.documentType) | defined }}
        </span> -->
        <span class="text" :title="info.documentLabel | defined">{{ info.documentLabel | defined }}</span>
      </div>
      <!-- <div class="end-draft-item">
        <span class="lable">{{ $t('orderInfo.industry') }}:</span>
        <span class="text">{{ $store.getters['app/getIndustryName'](info.field) | defined }}</span>
      </div> -->
      <div class="end-draft-item">
        <span class="lable">{{ $t('orderInfo.source') }}:</span>
        <span class="text">{{ $store.getters['app/getLangNameByCode'](info.sourceCode) | defined }}</span>
      </div>
      <div class="end-draft-item">
        <span class="lable">{{ $t('orderInfo.target') }}:</span>
        <span class="text">{{ $store.getters['app/getLangNameByCode'](info.targetCodeList) | defined }}</span>
      </div>
      <div class="end-draft-item" v-if="info.feedback">
        <span class="lable">{{ $t('orderInfo.suggestions') }}:</span>
        <div class="text">
          <pre class="m0 text-wrap" v-if="info.feedback" v-html="info.feedback" />
          <span v-else>---</span>
          <div class="download-wrap mt-1" v-if="feedbackAttachmentList.length">
            <a-space :size="10">
              <a-button
                v-for="item in feedbackAttachmentList"
                :key="item.id"
                class="p0"
                type="link"
                @click="linkDownload(item.url)"
              >
                {{ item.name }} <a-icon type="download" />
              </a-button>
            </a-space>
          </div>
        </div>
      </div>
      <div class="end-draft-item" v-if="info.feedbackDueDate">
        <span class="lable">{{ $t('orderInfo.new_delivery_time') }}:</span>
        <span class="text">{{ info.feedbackDueDate | dateFormat($t('dateFormat.c')) }}</span>
      </div>
      <div
        class="end-draft-item"
        v-if="
          info.targetFileList &&
          info.targetFileList.length > 0 &&
          info.targetFileList[info.targetFileList.length - 1].remark
        "
      >
        <span class="lable">{{ $t('orderInfo.final_document_show') }}</span>
        <span class="text" :title="info.targetFileList[info.targetFileList.length - 1].remark">{{
          info.targetFileList[info.targetFileList.length - 1].remark
        }}</span>
      </div>
      <div
        class="end-draft-item"
        style="margin-top: 28px; margin-bottom: 10px; text-align: center; display: block; margin-left: -15px"
      >
        <span class="lable" style="opacity: 0"></span>
        <FRButton v-if="feedbackHistoryData.length > 0" @my-click="showFeedbackRecords = !showFeedbackRecords" />
      </div>
      <!-- 反馈历史记录 -->
      <FeedbackRecords :show="showFeedbackRecords" :feedbackFileList="feedbackHistoryData" width="253px" />

      <!-- 历史终稿记录 -->
      <FinalDraftHistory
        class="mt-3"
        v-if="finalDraftHistory && finalDraftHistory.length > 0"
        :isDownloadDrawer="true"
        :finalDraftHistory="finalDraftHistory"
      />

      <template v-slot:footer>
        <a-space :size="10">
          <!-- 反馈修改仅在未修改时显示 -->
          <!-- v-if="info.status !== 6 && info.status !== 7" -->
          <a-button class="neo-btn-primary" @click="handleFeedback">{{ $t('orderInfo.btn_feedback_edit') }}</a-button>
          <a-button class="neo-btn-primary" v-if="[5, 6, 7].includes(info.status)" @click="handleConfirm">{{
            $t('orderInfo.btn_submit_finish')
          }}</a-button>
          <a-button type="primary" @click="handleDownload" :loading="finishLoading">{{
            $t('orderInfo.btn_download_finish')
          }}</a-button>
        </a-space>
      </template>
    </NeoDrawerLayout>
    <!-- 确认终稿 -->
    <ConfirmEndDraftModal
      :info="info"
      :visible.sync="confirmVisible"
      @closeDrawer="handleClose"
      @getAvaScore="getAvaScore"
    />
    <!-- 评价均分 -->
    <AverageDisplayModal :visible.sync="confirmVisibleEnd" :avaScore="avaScore" @close="reallyClose" />
    <!-- 反馈修改 -->
    <FeedbackModal :info="info" :visible.sync="feedbackVisible" />
  </a-drawer>
</template>

<script>
import { downloadUrl } from '@/utils/utils'
import NeoTag from '@/components/common/NeoTag'
import ConfirmEndDraftModal from './ConfirmEndDraftModal'
import FeedbackModal from './FeedbackModal'
import AverageDisplayModal from '../component/averageDisplayModal'
import FeedbackRecords from './QuotationDrawer/FeedbackRecords/index'
import FRButton from './QuotationDrawer/FeedbackRecords/FRButton'
import sendFinalDraftDownloadEmail from '@/mixins/sendFinalDraftDownloadEmail.js'
import FinalDraftHistory from './QuotationDrawer/FeedbackRecords/FinalDraftHistory.vue'

export default {
  name: 'DownloadEndDraft',
  mixins: [sendFinalDraftDownloadEmail],
  components: {
    NeoTag,
    ConfirmEndDraftModal,
    FeedbackModal,
    AverageDisplayModal,
    FeedbackRecords,
    FRButton,
    FinalDraftHistory,
  },
  props: {
    // 订单详情
    info: {
      type: Object,
      default: () => ({}),
    },
    showAutoConfirmDate: {
      type: Boolean,
      default: false,
    },
    // 自动确认的时分秒
    autoConfirmDate: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
    feedbackHistoryData: {
      type: Array,
      default: () => [],
    },
    finalDraftHistory: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localeDict: window.$g.localeDict,
      // 确认终稿visible
      confirmVisible: false,
      // 反馈修改visible
      feedbackVisible: false,
      // 评价均分visible
      confirmVisibleEnd: false,
      avaScore: 0,
      showFeedbackRecords: false,
      finishLoading: false,
      showFinalDraftHistory: false,
    }
  },
  computed: {
    // 反馈附件
    feedbackAttachmentList() {
      if (this.info.feedbackFileList && this.info.feedbackFileList.length) {
        return this.info.feedbackFileList
      } else {
        return []
      }
    },
  },
  methods: {
    // 获取订单反馈历史
    // async feedbackHistory() {
    //   try {
    //     const info = await this.$http({
    //       key: 'feedbackHistory',
    //       params: {
    //         id: this.$route.query.id,
    //       },
    //     })
    //     this.feedbackHistoryData = info ?? []
    //   } catch (err) {
    //     this.$httpNotify(err)
    //   }
    // },
    getAvaScore(avaScore) {
      this.avaScore = avaScore
    },
    linkDownload(url) {
      downloadUrl(url)
    },

    // 下载终稿
    async handleDownload() {
      try {
        this.finishLoading = true
        const { targetFileList } = await this.$http({
          key: 'orderProcessInfo',
          params: {
            id: this.info.id,
          },
        })
        if (targetFileList && targetFileList.length) {
          targetFileList.forEach(async ({ url }) => {
            downloadUrl(url)
            await this.sendFinalDraftDownloadEmail()
            this.finishLoading = false
          })
        } else {
          this.finishLoading = false
          this.$message.warn(this.$t('tips.tip1'))
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // async sendFinalDraftDownloadEmail() {
    //   const t = this.info.downloadTimes
    //   if (this.$g.noEmpty(t) && ~~t === 0) {
    //     try {
    //       const params = { id: this.$route.query.id }
    //       // eslint-disable-next-line no-unused-vars
    //       const data = await this.$http('sendFinalDraftDownloadEmail', params)
    //       this.getOrderInfo()
    //     } catch (err) {
    //       this.$httpNotify(err)
    //     }
    //   }
    // },

    handleClose(type) {
      // 弹出评价弹框-待点击确认后再触发关闭
      if (type === 1) {
        this.confirmVisibleEnd = true
      } else {
        this.reallyClose()
      }
    },
    reallyClose() {
      this.confirmVisibleEnd = false
      this.$emit('update:visible', false)
    },

    handleConfirm() {
      const _this = this
      this.$warning({
        title: (
          <span style="font-weight: bold;color: #46546C;font-size: 16px;">
            {this.$t('orderInfo.btn_submit_finish')}
          </span>
        ),
        content: (
          <div style="font-weight: normal;color: #46546C;line-height: 22px;font-size: 13px;">
            <div>{this.$t('orderInfo.confirm_final_draft')}</div>
            <div>{this.$t('orderInfo.step_irrevocable')}</div>
          </div>
        ),
        closable: true,
        centered: true,
        okText: this.$t('orderInfo.confirm'),
        async onOk() {
          try {
            await _this.$http('orderProcessProduceConfirm', {
              orderId: _this.info.id,
            })
            _this.confirmVisible = true
          } catch (err) {
            _this.$httpNotify(err)
          }
        },
      })
    },

    handleFeedback() {
      this.feedbackVisible = true
    },
  },
}
</script>
